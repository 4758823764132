/**
 * BACE application admin dashboard
 * Author: BACE IT CSU
 * Module/App: Main Js
 */
window._ = require('lodash');

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('category-page', require('./components/Category.vue').default);
Vue.component('add-category-page', require('./components/AddCategory.vue').default);
Vue.component('category-item-page', require('./components/CategoryItem.vue').default);
Vue.component('form-design-page', require('./components/FormDesign.vue').default);
Vue.component('add-form-field-page', require('./components/AddFormField.vue').default);
Vue.component('form-field-page', require('./components/FormField.vue').default);
Vue.component('form-field-exec-page', require('./components/FormFieldExec.vue').default);
Vue.component('form-exec-page', require('./components/FormExec.vue').default);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
