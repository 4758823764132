var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _c("h5", [
      _vm._v("Form ID for " + _vm._s(_vm.execType) + ":" + _vm._s(_vm.formId))
    ]),
    _vm._v(" "),
    _c("form", { attrs: { action: "/api/forms/submitform", method: "POST" } }, [
      _c("input", {
        attrs: { type: "hidden", name: "formId" },
        domProps: { value: _vm.formId }
      }),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "list-group" },
        [
          _vm._l(_vm.formFieldWrappers, function(formFieldWrapper) {
            return _c(
              "li",
              {
                key: formFieldWrapper.formField.id,
                staticClass: "list-group-item"
              },
              [
                _c("form-field-exec-page", {
                  ref: "fieldComponents",
                  refInFor: true,
                  attrs: { formFieldWrapper: formFieldWrapper },
                  on: { "update-field": _vm.updateField }
                })
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("hr")
        ],
        2
      ),
      _vm._v(" "),
      _vm.execType === "live"
        ? _c("div", { staticClass: "card panel panel-body submit" }, [
            _c("div", { staticClass: "card-body   " }, [
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success btn-xs formChanger",
                    attrs: {
                      type: "button",
                      id: "save",
                      "data-loading-text": "Saving...",
                      "data-complete-text": "Save"
                    },
                    on: { click: _vm.submitFormExec }
                  },
                  [_vm._v("Submit")]
                ),
                _vm._v(" "),
                _c("input", {
                  staticClass: "btn btn-warning",
                  attrs: { type: "submit", value: "Submit2", name: "Submit2" }
                })
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.execType === "live-update"
        ? _c("div", { staticClass: "card panel panel-body submit" }, [
            _c("div", { staticClass: "card-body   " }, [
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success btn-xs formChanger",
                    attrs: {
                      type: "button",
                      id: "save",
                      "data-loading-text": "Saving...",
                      "data-complete-text": "Save"
                    },
                    on: { click: _vm.updateSubmittedForm }
                  },
                  [_vm._v("Update")]
                ),
                _vm._v(" "),
                _c("input", {
                  staticClass: "btn btn-warning",
                  attrs: { type: "submit", value: "Submit2", name: "Submit2" }
                })
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }