<template>
<div id="app">
    <h5>Form IDX for Design:{{formId}}</h5>
    <add-form-field-page v-on:add-field="addField"></add-form-field-page>
    <ul class="list-group">


        <li class="list-group-item" v-for="formFieldWrapper in formFieldWrappers" :key="formFieldWrapper.formField.id">
            
            <form-field-page v-on:del-field="deleteField" v-on:update-field="updateField" v-on:up-field="moveUp" v-on:down-field="moveDown" v-bind:formFieldWrapper="formFieldWrapper" ref = "fieldComponents"></form-field-page>

            <!--
            <div class="todo-item" v-bind:class="{'is-complete':category.completed}">
                <input type="checkbox" v-on:change="markComplete(category.id)" v-bind:checked="category.completed"> {{ category.name }} <button @click="deleteCategory(category.id)" class="del">x</button>
            </div>
            -->
        </li> 
        <hr/>

    </ul>
    <div class="card panel panel-body submit">
        <div class="card-body   ">
            <div>
                <button v-on:click="submitFormDesign" class="btn btn-success btn-xs formChanger" id="submiter" data-loading-text="Saving..." data-complete-text="Save">Save</button>
                <button @click="showPreview" class="btn btn-secondary" data-toggle="modal" data-target="#previewModal">Preview</button>
                <!--
                    <button v-on:click="previewFormDesign" class="btn btn-secondary btn-xs formChanger" id="previewer" >Preview</button>
                -->
            </div>
        </div>
    </div>
    <!-- PREVIEW MODAL -->
    <div v-if="readyForPreview" class="modal" id="previewModal" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog" style="max-width: 100%;max-height: 100%;">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Form Design Preview</h5>
            <button class="close" data-dismiss="modal">&times;</button>
          </div>
          <div class="modal-body">
            <form-exec-page :previewFormFieldWrappers="previwFormFieldWrappers" :form-id="formId" :exec-type="'preview'" :key="formExecComponentKey"></form-exec-page>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>

export default {
    props: ["formId"],
    data: function(){
        return{
            formFields: [],
            formFieldWrappers: [],
            readyForPreview: false,
            previwFormFieldWrappers: [],
            formExecComponentKey: 0
            /*
                {
                    formField: {
                        id:1
                    },
                    changeFlag: false,
                    displayType: 'design-edit'
                },
                 {
                    formField: {
                        id:2
                    },
                    changeFlag: false,
                    displayType: 'design-edit'
                }
            ]*/
        }
    },
    mounted(){
        this.loadFields();
    },
    methods: {
        loadFields: function(){
            console.log("calling GetFields for formId:",this.formId);
            /*this.formField.smaple = [
              {
                  attribute: "short_answer.1f5e76b8",
                  created_at: "2020-12-17T14:51:33.000000Z",
                  filled: false,
                  form_definition_id: 2,
                  id: 17,
                  name: null,
                  options: null,
                  question: null,
                  required: null,
                  status: 1,
                  template: "short-answer",
                  updated_at: "2020-12-17T14:51:33.000000Z"
              }
              this.formFieldWrappers.sample =[
                  {
                    formField: this.formField,
                    displayType: design-edit/design-preview/fill-edit/fill-preview
                    changeFlag: false/true
                  }
              ]
            ]*/
            let url = '/api/forms/'+this.formId;
            axios.get(url)
            .then( (response) => {
                console.log(response.data.form_definition_fields);
                //this.formFields = response.data.form_definition_fields;
                for (let i = 0; i < response.data.form_definition_fields.length; i++) {
                    let tempFieldWrapper = {
                        formField: response.data.form_definition_fields[i],
                        changeFlag: false,
                        displayType: 'design-edit'
                    }
                    this.formFieldWrappers.push(tempFieldWrapper);
                }
                console.log("Retrieved fields:",this.formFieldWrappers);
            })
            .catch(function(error){
                console.log(error);
            });
            //this.previwFormFieldWrappers = [...this.formFieldWrappers];
            
        },
        addField: function(newField){
            //const { fieldType, fieldId } = newField;
            console.log("Calling add field for form and fieldType:", this.formId, newField.template);
            
            axios.post('/api/forms/addfield', {
                formId: this.formId,
                template: newField.template
            })
            .then((response) => {
                console.log("Successfully added:");
                const addedFieldWrapper = {
                    formField: response.data,
                    displayType: 'design-edit',
                    changeFlag: false
                };
                if(newField.template != 'linear-scale'){
                    addedFieldWrapper.formField.options = [];
                }else{
                    addedFieldWrapper.formField.options = {
                        min:{
                            value: '',
                            label: ''
                        },
                        max:{
                            value: '',
                            label: ''
                        }
                    };
                }
                
                //this.formFieldWrappers = [...this.formFieldWrappers, addedFieldWrapper];
                this.formFieldWrappers.push(addedFieldWrapper);
                console.log(this.formFieldWrappers);
            })
            .catch(err => console.log(err));
        
        },
        updateField: function(updatedFieldWrapper){
            console.log("Calling update for fieldObject:", updatedFieldWrapper);
            
            axios.post('/api/forms/updatefield', {
                id: updatedFieldWrapper.formField.id,
                name: updatedFieldWrapper.formField.name,
                options: updatedFieldWrapper.formField.options,
                question: updatedFieldWrapper.formField.question,
                required: updatedFieldWrapper.formField.required,
                status: updatedFieldWrapper.formField.status,
                template: updatedFieldWrapper.formField.template
            })
            .then((response) => {
                console.log("Successfully updated:");
                console.log(response.data);
            })
            .catch(err => console.log(err));
        
        },
         deleteField(selectedFieldId) {
             console.log("calling delete for formID and fieldID:", selectedFieldId);
             for (let i = 0; i < this.formFieldWrappers.length; i++) {
                if (this.formFieldWrappers[i].formField.id === selectedFieldId){
                    let url = '/api/forms/deletefield/'+selectedFieldId;
                    console.log('URL:',url);
                    axios.delete(url)
                    .then((response) => {
                        console.log("Successfully deleted:",response);
                        this.formFieldWrappers.splice(i, 1);
                    })
                    .catch(err => console.log(err));
                    }
                    //console.log(this.categories[i]);
                }     
        },
        submitFormDesign(selectedFieldId) {
            console.log("Submitting the form...");
            for (let i=0; i<this.formFieldWrappers.length; i++){
                let updatedFormFieldWrapper = this.$refs.fieldComponents[i].getUpdatedValues();
                //console.log("Updating field for ID:",updatedFormFieldWrapper.formField.id);
                //console.log("ChangeFlag:",updatedFormFieldWrapper.changeFlag);
                //console.log("ChangeFlag:",this.$refs.fieldComponents[i].getUpdatedValues().changeFlag);
                if(updatedFormFieldWrapper.changeFlag){
                    this.updateField (updatedFormFieldWrapper);
                    this.readyForPreview = true; 
                }
            }
        },
        previewFormDesign(){
            console.log("Opening the form in preview...");  
        },
        showPreview(){
            console.log("preparing for preview..."); 
            let tempUpdatedFieldWrappers = [];
            for (let i=0; i<this.formFieldWrappers.length; i++){
                //let updatedFormFieldWrapper = this.$refs.fieldComponents[i].getUpdatedValues();
                //console.log("Updating field for ID:",updatedFormFieldWrapper.formField.id);
                //console.log("ChangeFlag:",updatedFormFieldWrapper.changeFlag);
                //console.log("ChangeFlag:",this.$refs.fieldComponents[i].getUpdatedValues().changeFlag);
                tempUpdatedFieldWrappers.push(this.$refs.fieldComponents[i].getUpdatedValues());
            }
            this.previwFormFieldWrappers = JSON.parse(JSON.stringify(tempUpdatedFieldWrappers));
            console.log("previwing with latest data:",this.previwFormFieldWrappers);
            //this.previwFormFieldWrappers = [...this.formFieldWrappers];
            
            for (let i=0; i<this.previwFormFieldWrappers.length; i++){
                this.previwFormFieldWrappers[i].displayType = 'execute';
            }
            //this.readyForPreview = false;
            this.formExecComponentKey += 1; 
            this.readyForPreview = true;
            //this.$root.$emit("bv::show::modal", "loginModal");

        },
        
        moveUp(selectedId) {
            console.log("moving up...");
            let selectedIndex=0;
            let selectedFieldWrapper;
            for (let i = 0; i < this.formFieldWrappers.length; i++) {
                  if (this.formFieldWrappers[i].formField.id === selectedId && i>0){
                    console.log("Selected fieldWrapper:",this.formFieldWrappers[i]);
                    selectedIndex = i;
                    selectedFieldWrapper = this.formFieldWrappers[i];
                    break;
                }     
            }
            if(selectedIndex>0){
                this.formFieldWrappers.splice(selectedIndex, 1);
                this.formFieldWrappers.splice(selectedIndex-1, 0, selectedFieldWrapper);
            }
        },
        moveDown(selectedId) {
            console.log("moving down...")
            let selectedIndex=0;
            let selectedFieldWrapper;
            for (let i = 0; i < this.formFieldWrappers.length; i++) {
                  if (this.formFieldWrappers[i].formField.id === selectedId && i<this.formFieldWrappers.length-1){
                    console.log("Selected fieldWrapper:",this.formFieldWrappers[i]);
                    selectedIndex = i;
                    selectedFieldWrapper = this.formFieldWrappers[i];
                    break;
                }     
            }
            if(selectedIndex<this.formFieldWrappers.length-1){
                this.formFieldWrappers.splice(selectedIndex, 1);
                this.formFieldWrappers.splice(selectedIndex+1, 0, selectedFieldWrapper);
            }
        }
        
    }
}
</script>


