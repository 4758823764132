import { render, staticRenderFns } from "./Category.vue?vue&type=template&id=33972369&"
import script from "./Category.vue?vue&type=script&lang=js&"
export * from "./Category.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Projects\\GitHub\\app_code\\master\\V_0_0_7\\bace-application\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('33972369')) {
      api.createRecord('33972369', component.options)
    } else {
      api.reload('33972369', component.options)
    }
    module.hot.accept("./Category.vue?vue&type=template&id=33972369&", function () {
      api.rerender('33972369', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/Category.vue"
export default component.exports