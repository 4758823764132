$(document).ready(function () {
    window._token = $('meta[name="csrf-token"]').attr('content')
  
    moment.updateLocale('en', {
      week: {dow: 1} // Monday is the first day of the week
    })
  
    $('.date').datetimepicker({
      format: 'YYYY-MM-DD',
      locale: 'en',
      icons: {
        up: 'fas fa-chevron-up',
        down: 'fas fa-chevron-down',
        previous: 'fas fa-chevron-left',
        next: 'fas fa-chevron-right'
      }
    })
  
    $('.datetime').datetimepicker({
      format: 'YYYY-MM-DD HH:mm:ss',
      locale: 'en',
      sideBySide: true,
      icons: {
        up: 'fas fa-chevron-up',
        down: 'fas fa-chevron-down',
        previous: 'fas fa-chevron-left',
        next: 'fas fa-chevron-right'
      }
    })
  
    $('.timepicker').datetimepicker({
      format: 'HH:mm:ss',
      icons: {
        up: 'fas fa-chevron-up',
        down: 'fas fa-chevron-down',
        previous: 'fas fa-chevron-left',
        next: 'fas fa-chevron-right'
      }
    })
  
    // for modals,  select2 needs to be manually initialised
    $(document).on('DOMNodeInserted', '.modal', function (event) {
      $(event.target).find('.select2').select2();
    });
  
  
    $(document).on('click','.select-all', function () {
      let $select2 = $(this).parent().siblings('.select2')
      $select2.find('option').prop('selected', 'selected')
      $select2.trigger('change')
    })
    $(document).on('click', '.deselect-all', function () {
      let $select2 = $(this).parent().siblings('.select2')
      $select2.find('option').prop('selected', '')
      $select2.trigger('change')
    })
  
    $('.select2').select2({
      // tags: true,
      templateSelection : function (tag, container){
        // here we are finding option element of tag and
       // if it has property 'locked' we will add class 'locked-tag' 
       // to be able to style element in select
       var $option = $('.select2 option[value="'+tag.id+'"]');
       if ($option.attr('locked')){
          $(container).addClass('locked-tag');
          tag.locked = true; 
       }
       return tag.text;
      },
    })
    .on('select2:unselecting', function(e){
      // before removing tag we check option element of tag and 
     // if it has property 'locked' we will create error to prevent all select2 functionality
      if ($(e.params.args.data.element).attr('locked')) {
          e.preventDefault();
       }
    });
  
    $('.treeview').each(function () {
      var shouldExpand = false
      $(this).find('li').each(function () {
        if ($(this).hasClass('active')) {
          shouldExpand = true
        }
      })
      if (shouldExpand) {
        $(this).addClass('active')
      }
    })
  
  
   // Calculate min height
    function containerHeight() {
      if($('.page-container').offset()) {
        var availableHeight = $(window).height() - $('.page-container').offset().top - $('.navbar-fixed-bottom').outerHeight();
  
        $('.page-container').attr('style', 'min-height:' + availableHeight + 'px');
      }
    }
  
    // Initialize
    containerHeight();
  
   
  
  
     // Mobile sidebar setup
      // -------------------------
  
      $(window).on('resize', function() {
        setTimeout(function() {
            containerHeight();
            
            if($(window).width() <= 768) {
  
                // Add mini sidebar indicator
                $('body').addClass('sidebar-xs-indicator');
  
                // Place right sidebar before content
                $('.sidebar-opposite').insertBefore('.content-wrapper');
  
                // Place detached sidebar before content
                $('.sidebar-detached').insertBefore('.container-detached');
  
                // Add mouse events for dropdown submenus
                $('.dropdown-submenu').on('mouseenter', function() {
                    $(this).children('.dropdown-menu').addClass('show');
                }).on('mouseleave', function() {
                    $(this).children('.dropdown-menu').removeClass('show');
                });
            }
            else {
  
                // Remove mini sidebar indicator
                $('body').removeClass('sidebar-xs-indicator');
  
                // Revert back right sidebar
                $('.sidebar-opposite').insertAfter('.content-wrapper');
  
                // Remove all mobile sidebar classes
                $('body').removeClass('sidebar-mobile-main sidebar-mobile-secondary sidebar-mobile-detached sidebar-mobile-opposite');
  
                // Revert left detached position
                if($('body').hasClass('has-detached-left')) {
                    $('.sidebar-detached').insertAfter('.container-detached');
                }
  
                // Revert right detached position
                else if($('body').hasClass('has-detached-right')) {
                    $('.sidebar-detached').insertAfter('.container-detached');
                }
  
                // Remove visibility of heading elements on desktop
                $('.page-header-content, .panel-heading, .panel-footer').removeClass('has-visible-elements');
                $('.heading-elements').removeClass('visible-elements');
  
                // Disable appearance of dropdown submenus
                $('.dropdown-submenu').children('.dropdown-menu').removeClass('show');
            }
        }, 100);
    }).resize();
  
  
     // Mobile sidebar controls
      // -------------------------
  
      // Toggle main sidebar
      $('.sidebar-mobile-main-toggle').on('click', function (e) {
        e.preventDefault();
        $('body').toggleClass('sidebar-mobile-main').removeClass('sidebar-mobile-secondary sidebar-mobile-opposite sidebar-mobile-detached');
    });
  
  
    // Toggle secondary sidebar
    $('.sidebar-mobile-secondary-toggle').on('click', function (e) {
        e.preventDefault();
        $('body').toggleClass('sidebar-mobile-secondary').removeClass('sidebar-mobile-main sidebar-mobile-opposite sidebar-mobile-detached');
    });
  
  
    // Toggle opposite sidebar
    $('.sidebar-mobile-opposite-toggle').on('click', function (e) {
        e.preventDefault();
        $('body').toggleClass('sidebar-mobile-opposite').removeClass('sidebar-mobile-main sidebar-mobile-secondary sidebar-mobile-detached');
    });
  
  
    // Toggle detached sidebar
    $('.sidebar-mobile-detached-toggle').on('click', function (e) {
        e.preventDefault();
        $('body').toggleClass('sidebar-mobile-detached').removeClass('sidebar-mobile-main sidebar-mobile-secondary sidebar-mobile-opposite');
    });
  
    $('.category-content').on('click', function (e) {
      $(".sidebar-mobile-detached-toggle").trigger("click");
    });
  
    $(document).on('submit', 'form', function(event) {
      if(!event.originalEvent.defaultPrevented) {
        disableAllBUttons();
      }
    });
  
  });
  
  function disableAllBUttons() {
    $('button').attr('disabled', 'disabled');
  }
  
  function enableAllBUttons() {
    $('button').removeAttr('disabled');
  }
  
  $(function() {
    // Setup
  
      function initScroll() {
          $(".sidebar-detached .sidebar").niceScroll({
              mousescrollstep: 100,
              cursorcolor: '#ccc',
              cursorborder: '',
              cursorwidth: 3,
              hidecursordelay: 100,
              autohidemode: 'scroll',
              horizrailenabled: false,
              preservenativescrolling: false,
              railpadding: {
                  right: 0.5,
                  top: 1.5,
                  bottom: 1.5
              }
          });
      }
  
      // Resize
      function resizeScroll() {
          $('.sidebar-detached .sidebar').getNiceScroll().resize();
      }
  
      // Remove
      function removeScroll() {
          $(".sidebar-detached .sidebar").getNiceScroll().remove();
          $(".sidebar-detached .sidebar").removeAttr('style').removeAttr('tabindex');
      }
  
  
      // Resize sidebar on scroll
      // ------------------------------
  
      // Resize detached sidebar vertically when bottom reached
      function resizeDetached() {
          $(window).on('load scroll', function() {
            if ($(window).scrollTop() > $(document).height() - $(window).height() - 60) {
              $('.sidebar-detached').addClass('fixed-sidebar-space');
              resizeScroll();
            }
            else {
              $('.sidebar-detached').removeClass('fixed-sidebar-space');
              resizeScroll();
            }
          });
      }
  
  
      // Affix detached sidebar
      // ------------------------------
  
      // Init nicescroll when sidebar affixed
      $('.sidebar-detached').on('affix.bs.affix', function() {
          initScroll();
          resizeDetached();
      });
  
      // When effixed top, remove scrollbar and its data
      $('.sidebar-detached').on('affix-top.bs.affix', function() {
          removeScroll();
  
          $(".sidebar-detached .sidebar").removeAttr('style').removeAttr('tabindex');
      });
  
      // Attach BS affix component to the sidebar
  
  
      // Remove affix and scrollbar on mobile
      $(window).on('resize', function() {
          setTimeout(function() {            
              if($(window).width() <= 768) {
  
                  // Remove nicescroll on mobiles
                  // removeScroll();
  
                  // Remove affix on mobile
                  $(window).off('.affix')
                  $('.sidebar-detached').removeData('affix').removeClass('affix affix-top affix-bottom');
                  $('.add-form-field-list').removeClass('hidden');
              } else {
                $('.add-form-field-list').addClass('hidden');
              }
          }, 100);
      }).resize();
  });
  