<template>
    <div class="todo-item" v-bind:class="{'is-complete':category.completed}">
        <input type="checkbox" v-on:change="markComplete" v-bind:checked="category.completed"> {{ category.name }} 
        <button @click="$emit('up-category', category.id)" class="btn btn-secondary">UP</button>
        <button @click="$emit('down-category', category.id)" class="btn btn-secondary">DOWN</button>
        <button @click="$emit('del-category', category.id)" class="del">x</button>
    </div>
</template>
<script>
export default {
    name: "CategoryItem",
    props: ["category"],
    
    methods: {
        markComplete(){
            this.category.completed = !this.category.completed;
            let url = '/api/categories/update/'+this.category.id;
            console.log('URL:',url);
            //console.log('Params:',this.category.name, this.category.completed);
            axios.post(url, {
                name: this.category.name,
                completed: this.category.completed
            })
            .then((response) => {
                console.log("Successfully updated:",response);
            })
            .catch(err => console.log(err));
            }
        }
    } 
</script>

<style scoped>
  .todo-item {
    background: #f4f4f4;
    padding: 10px;
    border-bottom: 1px #ccc dotted;
  }

  .is-complete {
    text-decoration: line-through;
  }

  .del {
    background: #ff0000;
    color: #fff;
    border: none;
    padding: 5px 9px;
    border-radius: 50%;
    cursor: pointer;
    float: right;
  }

    .updown {
    background: #cccc00;
    color: #000;
    border: none;
    padding: 5px 9px;
    border-radius: 50%;
    cursor: pointer;
    float: right;
  }

</style>