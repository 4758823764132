var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.addField($event)
          }
        }
      },
      [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.fieldType,
                expression: "fieldType"
              }
            ],
            attrs: { name: "field-type", id: "field-type" },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.fieldType = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          [
            _c("option", { attrs: { value: "" } }, [
              _vm._v("-----------------Select a template--------------------")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "short-answer" } }, [
              _vm._v("Short Answer")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "long-answer" } }, [
              _vm._v("Long Answer")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "multiple-choices" } }, [
              _vm._v("Multiple Choice")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "checkboxes" } }, [
              _vm._v("Check boxes")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "drop-down" } }, [
              _vm._v("Drop-down")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "linear-scale" } }, [
              _vm._v("Linear Scale")
            ])
          ]
        ),
        _vm._v(" "),
        _c("input", {
          staticClass: "btn btn-primary",
          attrs: { type: "submit", value: "Submit" }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }