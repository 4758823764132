<template>
    <div class="form-field">
    
    <div v-if="formFieldWrapper.displayType==='execute'"> 
        <div v-if="formFieldWrapper.formField.template === 'short-answer'" v-bind:id="formFieldWrapper.formField.id" class="row template short-answer">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="short-answer-question" class="label-xlg field-label"><span class="question">{{formFieldWrapper.formField.question}}</span></label>
                    <input type="text" class="form-control" v-model="filledValue.attributeValue" v-bind:id="formFieldWrapper.formField.attribute" v-bind:name="formFieldWrapper.formField.attribute" placeholder="Answer" maxlength="255" minlength="1">
                </div>
            </div>
        </div>

        <div v-else-if="formFieldWrapper.formField.template === 'long-answer'" v-bind:id="formFieldWrapper.formField.id" class="row template long-answer">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="" class="label-xlg field-label"><span class="question">{{formFieldWrapper.formField.question}}</span></label>
                    <textarea rows="1" cols="5" class="form-control elastic" v-model="filledValue.attributeValue" v-bind:id="formFieldWrapper.formField.attribute" v-bind:name="formFieldWrapper.formField.attribute" placeholder="Answer" maxlength="30000" data-rule-min-words="3"></textarea>
                </div>
            </div>
	    </div>

        <div v-else-if="formFieldWrapper.formField.template==='multiple-choices'" v-bind:id="formFieldWrapper.formField.id" class="row template multiple-choices">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="" class="label-xlg field-label"><span class="question">{{formFieldWrapper.formField.question}}</span></label>
                    <div class="options button radios">
                        <div v-for="(input, index) in inputs" v-bind:key="index" class="form-check">
                            <input class="form-check-input" type="radio" v-model="filledValue.attributeValue" v-bind:name="formFieldWrapper.formField.attribute" v-bind:value="input.option">
                            <label class="form-check-label" for="exampleRadios1">
                                {{input.option}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="formFieldWrapper.formField.template==='checkboxes'" v-bind:id="formFieldWrapper.formField.id" class="row template checkboxes">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="" class="label-xlg field-label"><span class="question">{{formFieldWrapper.formField.question}}</span></label>
                    <div class="options button checkboxes">
                        <div  v-for="(input, index) in inputs" v-bind:key="index" class="form-check">
                            <input type="checkbox" v-bind:name="formFieldWrapper.formField.attribute + '[]'" v-model="filledValue.attributeValueArray[index]" v-bind:value="input.option" class="form-check-input" id="defaultCheck1" :true-value="input.option" :false-value="''">
                            <label class="form-check-label" for="defaultCheck1">
                                {{input.option}}
                            </label>
                        </div>
                        {{filledValue.attributeValueArray}}
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="formFieldWrapper.formField.template==='drop-down'" v-bind:id="formFieldWrapper.formField.id" class="row template drop-down">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="" class="label-xlg field-label"><span class="question">{{formFieldWrapper.formField.question}}</span></label>
                    <div class="options select">
                        <select v-bind:name="formFieldWrapper.formField.attribute" v-model="filledValue.attributeValue" class="select" data-width="100%">
                            <option v-for="(input, index) in inputs" v-bind:key="index" >
                            {{input.option}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="formFieldWrapper.formField.template==='linear-scale'" v-bind:id="formFieldWrapper.formField.id" class="row template linear-scale">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="" class="label-xlg field-label"><span class="question">{{formFieldWrapper.formField.question}}</span></label>
                    <div class="options scale">
                        <div class="row">
                            <div class="col-md-8 col-md-offset-2">
                                <div class="input-group">
                                    {{linerScaleInputs.min.label}}
                                    <input type="range" v-bind:name="formFieldWrapper.formField.attribute" v-model="filledValue.attributeValue" class="form slider" id="" v-bind:min="linerScaleInputs.min.value" v-bind:max="linerScaleInputs.max.value" step="1">
                                    {{linerScaleInputs.max.label}}
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div v-else-if="formFieldWrapper.formField.template==='datee'" v-bind:id="formFieldWrapper.formField.id" class="row template date">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="" class="label-xlg field-label"><span class="question">{{formFieldWrapper.formField.question}}</span></label>
                    <input type="date" name="" class="form-control pickadate" value="" placeholder="" data-msg="Date is required">
                </div>
            </div>
        </div>

    </div>
</div>
</template>
<script>
export default {
    name: "FormField",
    props: ["formFieldWrapper"],
    data() {
    console.log("inside FieldComponent...prop.formFieldWrapper:",this.formFieldWrapper);
    return {
      //currentFormField: this.formFieldWrapper.formField,
      currentFormFieldWrapper: this.formFieldWrapper,
      inputs:[],
      filledValue:{
          id: this.formFieldWrapper.formField.id,
          template: this.formFieldWrapper.formField.template,
          attribute: this.formFieldWrapper.formField.attribute,
          attributeValue: '',
          attributeValueArray: []
      },
      /*
      formField.options = [
          {
          option: a,
          detail: b
        },
        {
          option: a,
          detail: b
        },
      ]
      */
      linerScaleInputs: {
          min:{
              value: '',
              label: ''
          },
          max:{
              value: '',
              label: ''
          }
      }
    }
  },
  watch: {
        'currentFormFieldWrapper.formField.question': function(val, oldVal){
            if (val != oldVal) {
                console.log("Question description changed to:",val);
                this.currentFormFieldWrapper.changeFlag = true;
            }
        },

        'linerScaleInputs.min.label': function(val, oldVal){
            if (val != this.formFieldWrapper.formField.options.min.label){
                console.log("linerScaleInputs.min.label changed to:", val);
                this.currentFormFieldWrapper.changeFlag = true;
            }
            
        },
        'linerScaleInputs.max.label': function(val, oldVal){
            if (val != this.formFieldWrapper.formField.options.max.label){
                console.log("'linerScaleInputs.max.label changed to:",val);
                this.currentFormFieldWrapper.changeFlag = true;
            }
            
        },
        'linerScaleInputs.min.value': function(val, oldVal){
            if (val != this.formFieldWrapper.formField.options.min.value){
                console.log("linerScaleInputs.min.value changed to:",val);
                this.currentFormFieldWrapper.changeFlag = true;
            }
        },
        'linerScaleInputs.max.value': function(val, oldVal){
            if (val != this.formFieldWrapper.formField.options.max.value){
                console.log("linerScaleInputs.max.value changed to:",val);
                this.currentFormFieldWrapper.changeFlag = true;
            }
        }
  },
  mounted(){
      this.initializeInputs();
  },
    methods: {
        initializeInputs: function(){
            if(this.formFieldWrapper.executionType!=null && this.formFieldWrapper.executionType==='update'){
                if (this.formFieldWrapper.formField.template != "checkboxes"){
                this.filledValue.attributeValue = this.formFieldWrapper.formField.field_value;
                console.log("attributeValue:",this.filledValue.attributeValue);
                }else{
                //this.filledValue.attributeValueArray = JSON.parse(JSON.stringify(this.formFieldWrapper.formField.field_value));
                this.filledValue.attributeValueArray = [...this.formFieldWrapper.formField.options]; 
                
                for(let y=0; y<this.filledValue.attributeValueArray.length; y++){
                    if (!this.formFieldWrapper.formField.field_value.includes(this.filledValue.attributeValueArray[y]))
                    this.filledValue.attributeValueArray[y] = '';
                }
                console.log("attributeValueArray:",this.filledValue.attributeValueArray);
                }
            }
            let tempOptions =[];
            if (this.formFieldWrapper.formField.template != "linear-scale"){
                if(this.formFieldWrapper.formField.options!=null){
                    for(let i=0; i<this.formFieldWrapper.formField.options.length; i++){
                        tempOptions.push({
                            option: this.formFieldWrapper.formField.options[i],
                            detail: ''
                        })
                    }
                }
                this.inputs = tempOptions;
            }else{
                this.linerScaleInputs = this.formFieldWrapper.formField.options;
            }
            console.log("initialized inputs:", this.inputs);
        },
        markDelete(){
           console.log("deleting field...", this.formFieldWrapper.formField.id);
           this.$emit('del-field', this.formFieldWrapper.formField.id);
        },
        markUpdate(){
            
            if (this.formFieldWrapper.formField.template != "linear-scale"){
                let options = [];
                for(let i=0; i<this.inputs.length; i++){
                    options[i] = this.inputs[i].option;
                }
                this.currentFormFieldWrapper.formField.options = options;
            }else{
                this.currentFormFieldWrapper.formField.options = this.linerScaleInputs;
            }
           console.log("updating field...", this.currentFormFieldWrapper.formField.id);
           this.$emit('update-field', this.currentFormFieldWrapper);
        },
        getFilledValues(){
            return JSON.parse(JSON.stringify(this.filledValue));
        },
        isUpdated(){
            return this.currentFormFieldWrapper.changeFlag;
        },
        addRow() {
            this.inputs.push({
            option: '',
            detail: ''
        })
        },
        deleteRow(index) {
            this.inputs.splice(index,1)
        }
    }
}
</script>

<style scoped>
  .form-field {
    background: #f4f4f4;
    padding: 10px;
    border-bottom: 1px #ccc dotted;
  }

  .is-complete {
    text-decoration: line-through;
  }

  .del {
    background: #ff0000;
    color: #fff;
    border: none;
    padding: 5px 9px;
    border-radius: 50%;
    cursor: pointer;
    float: right;
  }

    .updown {
    background: #cccc00;
    color: #000;
    border: none;
    padding: 5px 9px;
    border-radius: 50%;
    cursor: pointer;
    float: right;
  }

</style>