<template>
  <div>
    <form @submit.prevent="addField">
        <select name="field-type" id="field-type" v-model="fieldType" >
            <option value="">-----------------Select a template--------------------</option>
            <option value="short-answer">Short Answer</option>
            <option value="long-answer">Long Answer</option>
            <option value="multiple-choices">Multiple Choice</option>
            <option value="checkboxes">Check boxes</option>
            <option value="drop-down">Drop-down</option>
            <option value="linear-scale">Linear Scale</option>
            <!--
            <option value="datee">Date</option>
            -->
        </select>

<!--
      <input type="text" v-model="title" name="title" placeholder="Add Category...">
      -->
      <input type="submit" value="Submit" class="btn btn-primary">
    </form>
  </div>
</template>

<script>
export default {
  name: "AddField",
  data() {
    return {
      fieldType: '',
    }
  },
  methods: {
    addField() {
      const newField = {
        template: this.fieldType,
        required: 1,
        id: 0
      }
      // Send up to parent
      this.$emit('add-field', newField);

      this.fieldType = '';
    }
  }
}
</script>

<style scoped>
  form {
    display: flex;
  }

  input[type="text"] {
    flex: 10;
    padding: 5px;
  }

  input[type="submit"] {
    flex: 2;
  }
</style>