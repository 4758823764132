var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-field" }, [
    _vm.formFieldWrapper.displayType === "design-edit"
      ? _c("div", [
          _vm.formFieldWrapper.formField.template === "short-answer"
            ? _c(
                "div",
                {
                  staticClass: "card template short-answer",
                  attrs: {
                    id: _vm.formFieldWrapper.formField.id,
                    "data-attribute-type": "single"
                  }
                },
                [
                  _c("div", { staticClass: "card-body mb-10" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "mb-10" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.currentFormFieldWrapper.formField.question,
                              expression:
                                "currentFormFieldWrapper.formField.question"
                            }
                          ],
                          staticClass: "form-control input-xlg question-name",
                          attrs: {
                            type: "text",
                            id: "",
                            name: "question",
                            placeholder: "Question",
                            maxlength: "255",
                            minlength: "1",
                            required: ""
                          },
                          domProps: {
                            value:
                              _vm.currentFormFieldWrapper.formField.question
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.currentFormFieldWrapper.formField,
                                "question",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          value: "short answer",
                          readonly: ""
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-footer panel-footer-bordered" },
                    [
                      _c("div", { staticClass: "heading-elements" }, [
                        _c(
                          "span",
                          { staticClass: "heading-text text-semibold" },
                          [_vm._v("Short Answer Question Type")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "pull-right" }, [
                          _c("div", { staticClass: "heading-form" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "checkbox-inline checkbox-right checkbox-switchery switchery-sm"
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.currentFormFieldWrapper.formField
                                            .required,
                                        expression:
                                          "currentFormFieldWrapper.formField.required"
                                      }
                                    ],
                                    staticClass: "switchery question-required",
                                    attrs: {
                                      type: "checkbox",
                                      name: "required",
                                      id: ""
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.currentFormFieldWrapper.formField
                                          .required
                                      )
                                        ? _vm._i(
                                            _vm.currentFormFieldWrapper
                                              .formField.required,
                                            null
                                          ) > -1
                                        : _vm.currentFormFieldWrapper.formField
                                            .required
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a =
                                            _vm.currentFormFieldWrapper
                                              .formField.required,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.currentFormFieldWrapper
                                                  .formField,
                                                "required",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.currentFormFieldWrapper
                                                  .formField,
                                                "required",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.currentFormFieldWrapper
                                              .formField,
                                            "required",
                                            $$c
                                          )
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    "\n                                    Required\n                                "
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-danger btn-xs heading-btn question-delete",
                              attrs: {
                                type: "button",
                                "data-form": "",
                                "data-form-field": ""
                              },
                              on: { click: _vm.markDelete }
                            },
                            [_vm._v("Delete")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary btn-xs",
                              attrs: {
                                type: "button",
                                "data-form": "",
                                "data-form-field": ""
                              },
                              on: { click: _vm.markUpdate }
                            },
                            [_vm._v("Update")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light btn-xs",
                              on: {
                                click: function($event) {
                                  return _vm.$emit(
                                    "up-field",
                                    _vm.formFieldWrapper.formField.id
                                  )
                                }
                              }
                            },
                            [_vm._v("UP")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-dark btn-xs",
                              on: {
                                click: function($event) {
                                  return _vm.$emit(
                                    "down-field",
                                    _vm.formFieldWrapper.formField.id
                                  )
                                }
                              }
                            },
                            [_vm._v("DOWN")]
                          )
                        ])
                      ])
                    ]
                  )
                ]
              )
            : _vm.formFieldWrapper.formField.template === "long-answer"
            ? _c(
                "div",
                {
                  staticClass: "card template long-answer",
                  attrs: {
                    id: _vm.formFieldWrapper.formField.id,
                    "data-attribute-type": "single"
                  }
                },
                [
                  _c("div", { staticClass: "card-body mb-10" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "mb-10" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.currentFormFieldWrapper.formField.question,
                              expression:
                                "currentFormFieldWrapper.formField.question"
                            }
                          ],
                          staticClass: "form-control input-xlg question-name",
                          attrs: {
                            type: "text",
                            id: "",
                            name: "question",
                            placeholder: "Question",
                            maxlength: "255",
                            minlength: "3",
                            required: ""
                          },
                          domProps: {
                            value:
                              _vm.currentFormFieldWrapper.formField.question
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.currentFormFieldWrapper.formField,
                                "question",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          value: "long answer",
                          readonly: ""
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-footer panel-footer-bordered" },
                    [
                      _c("div", { staticClass: "heading-elements" }, [
                        _c(
                          "span",
                          { staticClass: "heading-text text-semibold" },
                          [_vm._v("Long Answer Question Type")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "pull-right" }, [
                          _c("div", { staticClass: "heading-form" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "checkbox-inline checkbox-right checkbox-switchery switchery-sm"
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.currentFormFieldWrapper.formField
                                            .required,
                                        expression:
                                          "currentFormFieldWrapper.formField.required"
                                      }
                                    ],
                                    staticClass: "switchery question-required",
                                    attrs: {
                                      type: "checkbox",
                                      name: "required",
                                      id: "",
                                      checked: "checked"
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.currentFormFieldWrapper.formField
                                          .required
                                      )
                                        ? _vm._i(
                                            _vm.currentFormFieldWrapper
                                              .formField.required,
                                            null
                                          ) > -1
                                        : _vm.currentFormFieldWrapper.formField
                                            .required
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a =
                                            _vm.currentFormFieldWrapper
                                              .formField.required,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.currentFormFieldWrapper
                                                  .formField,
                                                "required",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.currentFormFieldWrapper
                                                  .formField,
                                                "required",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.currentFormFieldWrapper
                                              .formField,
                                            "required",
                                            $$c
                                          )
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    "\n                                    Required\n                                "
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-danger btn-xs heading-btn question-delete",
                              attrs: {
                                type: "button",
                                "data-form": "",
                                "data-form-field": ""
                              },
                              on: { click: _vm.markDelete }
                            },
                            [_vm._v("Delete")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary btn-xs",
                              attrs: {
                                type: "button",
                                "data-form": "",
                                "data-form-field": ""
                              },
                              on: { click: _vm.markUpdate }
                            },
                            [_vm._v("Update")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light btn-xs",
                              on: {
                                click: function($event) {
                                  return _vm.$emit(
                                    "up-field",
                                    _vm.formFieldWrapper.formField.id
                                  )
                                }
                              }
                            },
                            [_vm._v("UP")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-dark btn-xs",
                              on: {
                                click: function($event) {
                                  return _vm.$emit(
                                    "down-field",
                                    _vm.formFieldWrapper.formField.id
                                  )
                                }
                              }
                            },
                            [_vm._v("DOWN")]
                          )
                        ])
                      ])
                    ]
                  )
                ]
              )
            : _vm.formFieldWrapper.formField.template === "multiple-choices"
            ? _c(
                "div",
                {
                  staticClass: "card template multiple-choices",
                  attrs: {
                    id: _vm.formFieldWrapper.formField.id,
                    "data-attribute-type": "multiple"
                  }
                },
                [
                  _c("div", { staticClass: "card-body mb-10" }, [
                    _c("div", { staticClass: "form-group mb-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.currentFormFieldWrapper.formField.question,
                            expression:
                              "currentFormFieldWrapper.formField.question"
                          }
                        ],
                        staticClass: "form-control input-xlg question-name",
                        attrs: {
                          type: "text",
                          id: "",
                          name: "question",
                          placeholder: "Question",
                          maxlength: "255",
                          minlength: "3",
                          required: ""
                        },
                        domProps: {
                          value: _vm.currentFormFieldWrapper.formField.question
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentFormFieldWrapper.formField,
                              "question",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-group mb-10" }, [
                          _c("div", { staticClass: "input-group" }, [
                            _vm._m(0),
                            _vm._v(" "),
                            _c(
                              "ul",
                              { staticClass: "list-group" },
                              _vm._l(_vm.inputs, function(input, index) {
                                return _c(
                                  "li",
                                  {
                                    key: index,
                                    staticClass: "list-group-item"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: input.option,
                                          expression: "input.option"
                                        }
                                      ],
                                      attrs: {
                                        type: "text",
                                        placeholder: "option"
                                      },
                                      domProps: { value: input.option },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            input,
                                            "option",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-xs btn-default remove-option",
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteRow(index)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "dripicons-cross noti-icon"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-xs btn-default add-option",
                                on: { click: _vm.addRow }
                              },
                              [_vm._v("Add option")]
                            )
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-footer panel-footer-bordered" },
                    [
                      _c("div", { staticClass: "heading-elements" }, [
                        _c("div", { staticClass: "row border" }, [
                          _c(
                            "span",
                            { staticClass: "heading-text text-semibold" },
                            [_vm._v("Multiple Choices Question Type")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "pull-right" }, [
                            _c("div", { staticClass: "heading-form" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "checkbox-inline checkbox-right checkbox-switchery switchery-sm"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.currentFormFieldWrapper
                                              .formField.required,
                                          expression:
                                            "currentFormFieldWrapper.formField.required"
                                        }
                                      ],
                                      staticClass:
                                        "switchery question-required d-block",
                                      attrs: {
                                        type: "checkbox",
                                        name: "required",
                                        id: "",
                                        checked: "checked"
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.currentFormFieldWrapper.formField
                                            .required
                                        )
                                          ? _vm._i(
                                              _vm.currentFormFieldWrapper
                                                .formField.required,
                                              null
                                            ) > -1
                                          : _vm.currentFormFieldWrapper
                                              .formField.required
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a =
                                              _vm.currentFormFieldWrapper
                                                .formField.required,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.currentFormFieldWrapper
                                                    .formField,
                                                  "required",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.currentFormFieldWrapper
                                                    .formField,
                                                  "required",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.currentFormFieldWrapper
                                                .formField,
                                              "required",
                                              $$c
                                            )
                                          }
                                        }
                                      }
                                    }),
                                    _vm._v(
                                      "\n                                    Required\n                                "
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "row border justify-content-center" },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-danger btn-xs heading-btn question-delete",
                                attrs: {
                                  type: "button",
                                  "data-form": "",
                                  "data-form-field": ""
                                },
                                on: { click: _vm.markDelete }
                              },
                              [_vm._v("Delete")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-xs",
                                attrs: {
                                  type: "button",
                                  "data-form": "",
                                  "data-form-field": ""
                                },
                                on: { click: _vm.markUpdate }
                              },
                              [_vm._v("Update")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-light btn-xs",
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "up-field",
                                      _vm.formFieldWrapper.formField.id
                                    )
                                  }
                                }
                              },
                              [_vm._v("UP")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-dark btn-xs",
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "down-field",
                                      _vm.formFieldWrapper.formField.id
                                    )
                                  }
                                }
                              },
                              [_vm._v("DOWN")]
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ]
              )
            : _vm.formFieldWrapper.formField.template === "checkboxes"
            ? _c(
                "div",
                {
                  staticClass: "card template checkboxes",
                  attrs: {
                    id: _vm.formFieldWrapper.formField.id,
                    "data-attribute-type": "multiple"
                  }
                },
                [
                  _c("div", { staticClass: "card-body mb-10" }, [
                    _c("div", { staticClass: "form-group mb-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.currentFormFieldWrapper.formField.question,
                            expression:
                              "currentFormFieldWrapper.formField.question"
                          }
                        ],
                        staticClass: "form-control input-xlg question-name",
                        attrs: {
                          type: "text",
                          id: "",
                          name: "question",
                          placeholder: "Question",
                          maxlength: "255",
                          minlength: "3",
                          required: ""
                        },
                        domProps: {
                          value: _vm.currentFormFieldWrapper.formField.question
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentFormFieldWrapper.formField,
                              "question",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-group mb-10" }, [
                          _c("div", { staticClass: "input-group" }, [
                            _vm._m(1),
                            _vm._v(" "),
                            _c(
                              "ul",
                              { staticClass: "list-group" },
                              _vm._l(_vm.inputs, function(input, index) {
                                return _c(
                                  "li",
                                  {
                                    key: index,
                                    staticClass: "list-group-item"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: input.option,
                                          expression: "input.option"
                                        }
                                      ],
                                      attrs: {
                                        type: "text",
                                        placeholder: "option"
                                      },
                                      domProps: { value: input.option },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            input,
                                            "option",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-xs btn-default remove-option",
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteRow(index)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "dripicons-cross noti-icon"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-xs btn-default add-option",
                                on: { click: _vm.addRow }
                              },
                              [_vm._v("Add option")]
                            )
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-footer panel-footer-bordered" },
                    [
                      _c("div", { staticClass: "heading-elements" }, [
                        _c(
                          "span",
                          { staticClass: "heading-text text-semibold" },
                          [_vm._v("Chechboxes Question Type")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "pull-right" }, [
                          _c("div", { staticClass: "heading-form" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "checkbox-inline checkbox-right checkbox-switchery switchery-sm"
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.currentFormFieldWrapper.formField
                                            .required,
                                        expression:
                                          "currentFormFieldWrapper.formField.required"
                                      }
                                    ],
                                    staticClass: "switchery question-required",
                                    attrs: {
                                      type: "checkbox",
                                      name: "required",
                                      id: "",
                                      checked: "checked"
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.currentFormFieldWrapper.formField
                                          .required
                                      )
                                        ? _vm._i(
                                            _vm.currentFormFieldWrapper
                                              .formField.required,
                                            null
                                          ) > -1
                                        : _vm.currentFormFieldWrapper.formField
                                            .required
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a =
                                            _vm.currentFormFieldWrapper
                                              .formField.required,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.currentFormFieldWrapper
                                                  .formField,
                                                "required",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.currentFormFieldWrapper
                                                  .formField,
                                                "required",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.currentFormFieldWrapper
                                              .formField,
                                            "required",
                                            $$c
                                          )
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    "\n                                    Required\n                                "
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-danger btn-xs heading-btn question-delete",
                              attrs: {
                                type: "button",
                                "data-form": "",
                                "data-form-field": ""
                              },
                              on: { click: _vm.markDelete }
                            },
                            [_vm._v("Delete")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary btn-xs",
                              attrs: {
                                type: "button",
                                "data-form": "",
                                "data-form-field": ""
                              },
                              on: { click: _vm.markUpdate }
                            },
                            [_vm._v("Update")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light btn-xs",
                              on: {
                                click: function($event) {
                                  return _vm.$emit(
                                    "up-field",
                                    _vm.formFieldWrapper.formField.id
                                  )
                                }
                              }
                            },
                            [_vm._v("UP")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-dark btn-xs",
                              on: {
                                click: function($event) {
                                  return _vm.$emit(
                                    "down-field",
                                    _vm.formFieldWrapper.formField.id
                                  )
                                }
                              }
                            },
                            [_vm._v("DOWN")]
                          )
                        ])
                      ])
                    ]
                  )
                ]
              )
            : _vm.formFieldWrapper.formField.template === "drop-down"
            ? _c(
                "div",
                {
                  staticClass: "card template drop-down",
                  attrs: {
                    id: _vm.formFieldWrapper.formField.id,
                    "data-attribute-type": "multiple"
                  }
                },
                [
                  _c("div", { staticClass: "card-body mb-10" }, [
                    _c("div", { staticClass: "form-group mb-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.currentFormFieldWrapper.formField.question,
                            expression:
                              "currentFormFieldWrapper.formField.question"
                          }
                        ],
                        staticClass: "form-control input-xlg question-name",
                        attrs: {
                          type: "text",
                          id: "",
                          name: "question",
                          placeholder: "Question",
                          maxlength: "255",
                          minlength: "3",
                          required: ""
                        },
                        domProps: {
                          value: _vm.currentFormFieldWrapper.formField.question
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentFormFieldWrapper.formField,
                              "question",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-group mb-10" }, [
                          _c("div", { staticClass: "input-group" }, [
                            _c(
                              "span",
                              {
                                staticClass: "input-group-addon sn",
                                attrs: { "data-sn": "1", id: "" }
                              },
                              [
                                _vm._v(
                                  "\n                                    1.\n                                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              { staticClass: "list-group" },
                              _vm._l(_vm.inputs, function(input, index) {
                                return _c(
                                  "li",
                                  {
                                    key: index,
                                    staticClass: "list-group-item"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: input.option,
                                          expression: "input.option"
                                        }
                                      ],
                                      attrs: {
                                        type: "text",
                                        placeholder: "option"
                                      },
                                      domProps: { value: input.option },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            input,
                                            "option",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-xs btn-default remove-option",
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteRow(index)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "dripicons-cross noti-icon"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-xs btn-default add-option",
                                on: { click: _vm.addRow }
                              },
                              [_vm._v("Add option")]
                            )
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-footer panel-footer-bordered" },
                    [
                      _c("div", { staticClass: "heading-elements" }, [
                        _c(
                          "span",
                          { staticClass: "heading-text text-semibold" },
                          [_vm._v("Drop Down Question Type")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "pull-right" }, [
                          _c("div", { staticClass: "heading-form" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "checkbox-inline checkbox-right checkbox-switchery switchery-sm"
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.currentFormFieldWrapper.formField
                                            .required,
                                        expression:
                                          "currentFormFieldWrapper.formField.required"
                                      }
                                    ],
                                    staticClass: "switchery question-required",
                                    attrs: {
                                      type: "checkbox",
                                      name: "required",
                                      checked: "checked"
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.currentFormFieldWrapper.formField
                                          .required
                                      )
                                        ? _vm._i(
                                            _vm.currentFormFieldWrapper
                                              .formField.required,
                                            null
                                          ) > -1
                                        : _vm.currentFormFieldWrapper.formField
                                            .required
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a =
                                            _vm.currentFormFieldWrapper
                                              .formField.required,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.currentFormFieldWrapper
                                                  .formField,
                                                "required",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.currentFormFieldWrapper
                                                  .formField,
                                                "required",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.currentFormFieldWrapper
                                              .formField,
                                            "required",
                                            $$c
                                          )
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    "\n                                    Required\n                                "
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-danger btn-xs heading-btn question-delete",
                              attrs: { type: "button" },
                              on: { click: _vm.markDelete }
                            },
                            [_vm._v("Delete")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary btn-xs",
                              attrs: {
                                type: "button",
                                "data-form": "",
                                "data-form-field": ""
                              },
                              on: { click: _vm.markUpdate }
                            },
                            [_vm._v("Update")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light btn-xs",
                              on: {
                                click: function($event) {
                                  return _vm.$emit(
                                    "up-field",
                                    _vm.formFieldWrapper.formField.id
                                  )
                                }
                              }
                            },
                            [_vm._v("UP")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-dark btn-xs",
                              on: {
                                click: function($event) {
                                  return _vm.$emit(
                                    "down-field",
                                    _vm.formFieldWrapper.formField.id
                                  )
                                }
                              }
                            },
                            [_vm._v("DOWN")]
                          )
                        ])
                      ])
                    ]
                  )
                ]
              )
            : _vm.formFieldWrapper.formField.template === "linear-scale"
            ? _c(
                "div",
                {
                  staticClass: "card template linear-scale",
                  attrs: {
                    id: _vm.formFieldWrapper.formField.id,
                    "data-attribute-type": "multiple"
                  }
                },
                [
                  _c("div", { staticClass: "card-body mb-10" }, [
                    _c("div", { staticClass: "form-group mb-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.currentFormFieldWrapper.formField.question,
                            expression:
                              "currentFormFieldWrapper.formField.question"
                          }
                        ],
                        staticClass: "form-control input-xlg question-name",
                        attrs: {
                          type: "text",
                          id: "",
                          name: "question",
                          placeholder: "Question",
                          maxlength: "255",
                          minlength: "2",
                          required: ""
                        },
                        domProps: {
                          value: _vm.currentFormFieldWrapper.formField.question
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentFormFieldWrapper.formField,
                              "question",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-10" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.linerScaleInputs.min.value,
                                  expression: "linerScaleInputs.min.value"
                                }
                              ],
                              staticClass:
                                "custom-select select min question-option key",
                              attrs: {
                                name: "min",
                                "data-width": "100%",
                                required: ""
                              },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.linerScaleInputs.min,
                                    "value",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { value: "0", id: "" },
                                  domProps: {
                                    selected:
                                      this.linerScaleInputs.min.value == 0
                                  }
                                },
                                [_vm._v("0")]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                {
                                  attrs: { value: "1", id: "" },
                                  domProps: {
                                    selected:
                                      this.linerScaleInputs.max.value == 1
                                  }
                                },
                                [_vm._v("1")]
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _vm._m(2),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.linerScaleInputs.max.value,
                                  expression: "linerScaleInputs.max.value"
                                }
                              ],
                              staticClass:
                                "custom-select select max question-option key",
                              attrs: {
                                name: "max",
                                "data-width": "100%",
                                required: ""
                              },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.linerScaleInputs.max,
                                    "value",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { value: "2", id: "" },
                                  domProps: {
                                    selected:
                                      this.linerScaleInputs.max.value == 2
                                  }
                                },
                                [_vm._v("2")]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                {
                                  attrs: { value: "3", id: "" },
                                  domProps: {
                                    selected:
                                      this.linerScaleInputs.max.value == 3
                                  }
                                },
                                [_vm._v("3")]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                {
                                  attrs: { value: "4", id: "" },
                                  domProps: {
                                    selected:
                                      this.linerScaleInputs.max.value == 4
                                  }
                                },
                                [_vm._v("4")]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                {
                                  attrs: { value: "5", id: "" },
                                  domProps: {
                                    selected:
                                      this.linerScaleInputs.max.value == 5
                                  }
                                },
                                [_vm._v("5")]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                {
                                  attrs: { value: "6", id: "" },
                                  domProps: {
                                    selected:
                                      this.linerScaleInputs.max.value == 6
                                  }
                                },
                                [_vm._v("6")]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                {
                                  attrs: { value: "7", id: "" },
                                  domProps: {
                                    selected:
                                      this.linerScaleInputs.max.value == 7
                                  }
                                },
                                [_vm._v("7")]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                {
                                  attrs: { value: "8", id: "" },
                                  domProps: {
                                    selected:
                                      this.linerScaleInputs.max.value == 8
                                  }
                                },
                                [_vm._v("8")]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                {
                                  attrs: { value: "9", id: "" },
                                  domProps: {
                                    selected:
                                      this.linerScaleInputs.max.value == 9
                                  }
                                },
                                [_vm._v("9")]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                {
                                  attrs: { value: "10", id: "" },
                                  domProps: {
                                    selected:
                                      this.linerScaleInputs.max.value == 10
                                  }
                                },
                                [_vm._v("10")]
                              )
                            ]
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "select-labels" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "input-group" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "input-group-addon min-value",
                                  attrs: { id: "" }
                                },
                                [_vm._v("1.")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.linerScaleInputs.min.label,
                                    expression: "linerScaleInputs.min.label"
                                  }
                                ],
                                staticClass:
                                  "form-control question-option-label min value",
                                attrs: {
                                  type: "text",
                                  id: "",
                                  name: "min-label",
                                  placeholder: "Label (min)"
                                },
                                domProps: {
                                  value: _vm.linerScaleInputs.min.label
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.linerScaleInputs.min,
                                      "label",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "input-group" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "input-group-addon max-value",
                                  attrs: { id: "" }
                                },
                                [_vm._v("5.")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.linerScaleInputs.max.label,
                                    expression: "linerScaleInputs.max.label"
                                  }
                                ],
                                staticClass:
                                  "form-control question-option-label max value",
                                attrs: {
                                  type: "text",
                                  id: "",
                                  name: "max-label",
                                  placeholder: "Label (max)"
                                },
                                domProps: {
                                  value: _vm.linerScaleInputs.max.label
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.linerScaleInputs.max,
                                      "label",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-footer panel-footer-bordered" },
                    [
                      _c("div", { staticClass: "heading-elements" }, [
                        _c(
                          "span",
                          { staticClass: "heading-text text-semibold" },
                          [_vm._v("Linear Scale Question Type")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "pull-right" }, [
                          _c("div", { staticClass: "heading-form" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "checkbox-inline checkbox-right checkbox-switchery switchery-sm"
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.currentFormFieldWrapper.formField
                                            .required,
                                        expression:
                                          "currentFormFieldWrapper.formField.required"
                                      }
                                    ],
                                    staticClass: "switchery question-required",
                                    attrs: {
                                      type: "checkbox",
                                      name: "required",
                                      id: "",
                                      checked: "checked"
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.currentFormFieldWrapper.formField
                                          .required
                                      )
                                        ? _vm._i(
                                            _vm.currentFormFieldWrapper
                                              .formField.required,
                                            null
                                          ) > -1
                                        : _vm.currentFormFieldWrapper.formField
                                            .required
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a =
                                            _vm.currentFormFieldWrapper
                                              .formField.required,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.currentFormFieldWrapper
                                                  .formField,
                                                "required",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.currentFormFieldWrapper
                                                  .formField,
                                                "required",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.currentFormFieldWrapper
                                              .formField,
                                            "required",
                                            $$c
                                          )
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    "\n                                    Required\n                                "
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-danger btn-xs heading-btn question-delete",
                              attrs: {
                                type: "button",
                                "data-form": "",
                                "data-form-field": ""
                              },
                              on: { click: _vm.markDelete }
                            },
                            [_vm._v("Delete")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary btn-xs",
                              attrs: {
                                type: "button",
                                "data-form": "",
                                "data-form-field": ""
                              },
                              on: { click: _vm.markUpdate }
                            },
                            [_vm._v("Update")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light btn-xs",
                              on: {
                                click: function($event) {
                                  return _vm.$emit(
                                    "up-field",
                                    _vm.formFieldWrapper.formField.id
                                  )
                                }
                              }
                            },
                            [_vm._v("UP")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-dark btn-xs",
                              on: {
                                click: function($event) {
                                  return _vm.$emit(
                                    "down-field",
                                    _vm.formFieldWrapper.formField.id
                                  )
                                }
                              }
                            },
                            [_vm._v("DOWN")]
                          )
                        ])
                      ])
                    ]
                  )
                ]
              )
            : _vm.formFieldWrapper.formField.template === "datee"
            ? _c(
                "div",
                {
                  staticClass: "card template datee",
                  attrs: {
                    id: _vm.formFieldWrapper.formField.id,
                    "data-attribute-type": "single"
                  }
                },
                [
                  _c("div", { staticClass: "card-body mb-10" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "mb-10" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.currentFormFieldWrapper.formField.question,
                              expression:
                                "currentFormFieldWrapper.formField.question"
                            }
                          ],
                          staticClass: "form-control input-xlg question-name",
                          attrs: {
                            type: "text",
                            id: "",
                            name: "question",
                            placeholder: "Question",
                            maxlength: "255",
                            minlength: "2",
                            required: ""
                          },
                          domProps: {
                            value:
                              _vm.currentFormFieldWrapper.formField.question
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.currentFormFieldWrapper.formField,
                                "question",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _vm._m(3)
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-footer panel-footer-bordered" },
                    [
                      _c("div", { staticClass: "heading-elements" }, [
                        _c(
                          "span",
                          { staticClass: "heading-text text-semibold" },
                          [_vm._v("Date Question Type")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "pull-right" }, [
                          _c("div", { staticClass: "heading-form" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "checkbox-inline checkbox-right "
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.currentFormFieldWrapper.formField
                                            .required,
                                        expression:
                                          "currentFormFieldWrapper.formField.required"
                                      }
                                    ],
                                    staticClass: "question-required",
                                    attrs: {
                                      type: "checkbox",
                                      name: "required",
                                      id: "",
                                      checked: "checked"
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.currentFormFieldWrapper.formField
                                          .required
                                      )
                                        ? _vm._i(
                                            _vm.currentFormFieldWrapper
                                              .formField.required,
                                            null
                                          ) > -1
                                        : _vm.currentFormFieldWrapper.formField
                                            .required
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a =
                                            _vm.currentFormFieldWrapper
                                              .formField.required,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.currentFormFieldWrapper
                                                  .formField,
                                                "required",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.currentFormFieldWrapper
                                                  .formField,
                                                "required",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.currentFormFieldWrapper
                                              .formField,
                                            "required",
                                            $$c
                                          )
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    "\n                                    Required\n                                "
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-danger btn-xs heading-btn question-delete",
                              attrs: {
                                type: "button",
                                "data-form": "",
                                "data-form-field": ""
                              },
                              on: { click: _vm.markDelete }
                            },
                            [_vm._v("Delete")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary btn-xs",
                              attrs: {
                                type: "button",
                                "data-form": "",
                                "data-form-field": ""
                              },
                              on: { click: _vm.markUpdate }
                            },
                            [_vm._v("Update")]
                          )
                        ])
                      ])
                    ]
                  )
                ]
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "icon-radio-checked" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "icon-checkbox-checked" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-1" }, [
      _c("div", { staticClass: "form-control-static text-center" }, [
        _vm._v("to")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group col-md-6" }, [
      _c("span", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "icon-calendar3" })
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: { type: "text", placeholder: "Day, Month, Year", readonly: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }