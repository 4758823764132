<template>
<div id="app">
    <add-category-page v-on:add-category="addCategory"></add-category-page>
    <ul class="list-group">

        <li class="list-group-item" v-for="category in categories" :key="category.id">
            <category-item-page v-bind:category="category" v-on:del-category="deleteCategory" v-on:up-category="moveUp(category.id)" v-on:down-category="moveDown(category.id)"></category-item-page>
            <!--
            <div class="todo-item" v-bind:class="{'is-complete':category.completed}">
                <input type="checkbox" v-on:change="markComplete(category.id)" v-bind:checked="category.completed"> {{ category.name }} <button @click="deleteCategory(category.id)" class="del">x</button>
            </div>
            -->
        </li> 
        <hr/>

    </ul>
</div>
</template>

<script>

export default {
    data: function(){
        return{
            categories: []
        }
    },
    mounted(){
        this.loadCategories();
    },
    methods: {
        loadCategories: function(){
            axios.get('/api/categories')
            .then( (response) => {
                this.categories = response.data.data;
                console.log(this.categories);
            })
            .catch(function(error){
                console.log(error);
            });
        },
        addCategory: function(newCategory){
            const { title, completed } = newCategory;
            console.log(title, completed);
            axios.post('/api/categories/add', {
                name: title,
                completed: completed
            })
            .then((response) => {
                console.log("Successfully added:",response.data);
                const addedCategory = {
                    id: response.data.id,
                    name: response.data.name,
                    completed: response.data.completed
                }
                this.categories = [...this.categories, addedCategory];
                console.log("this.categories:",this.categories);
            })
            .catch(err => console.log(err));
        },
         deleteCategory(selectedId) {
             for (let i = 0; i < this.categories.length; i++) {
                if (this.categories[i].id === selectedId){
                    let url = '/api/categories/delete/'+this.categories[i].id;
                    console.log('URL:',url);
                    axios.delete(url)
                    .then((response) => {
                        console.log("Successfully deleted:",response);
                        this.categories.splice(i, 1);
                    })
                    .catch(err => console.log(err));
                    }
                    //console.log(this.categories[i]);
                }     
        },
        moveUp(selectedId) {
            console.log("moving up...");
            let selectedIndex=0;
            let selectedCategory;
            for (let i = 0; i < this.categories.length; i++) {
                  if (this.categories[i].id === selectedId && i>0){
                    //console.log(this.categories[i]);
                    selectedIndex = i;
                    selectedCategory = this.categories[i];
                    break;
                }     
            }
            if(selectedIndex>0){
                this.categories.splice(selectedIndex, 1);
                this.categories.splice(selectedIndex-1, 0, selectedCategory);
            }
        },
        moveDown(selectedId) {
            console.log("moving down...")
            let selectedIndex=0;
            let selectedCategory;
            for (let i = 0; i < this.categories.length; i++) {
                  if (this.categories[i].id === selectedId && i<this.categories.length-1){
                    //console.log(this.categories[i]);
                    selectedIndex = i;
                    selectedCategory = this.categories[i];
                    break;
                }     
            }
            if(selectedIndex>0){
                this.categories.splice(selectedIndex, 1);
                this.categories.splice(selectedIndex+1, 0, selectedCategory);
            }
        }
        /*
        markComplete(selectedId) {
             for (let i = 0; i < this.categories.length; i++) {
                if (this.categories[i].id === selectedId){
                    this.categories[i].completed = !this.categories[i].completed;
                    let url = '/api/categories/update/'+this.categories[i].id;
                    console.log('URL:',url);
                    console.log('Params:',this.categories[i].name, this.categories[i].completed);
                    axios.post(url, {
                        name: this.categories[i].name,
                        completed: this.categories[i].completed
                    })
                    .then((response) => {
                        console.log("Successfully updated:",response);
                    })
                    .catch(err => console.log(err));
                    }
                    //console.log(this.categories[i]);
                }     
        }*/
    }
}
</script>


