var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "todo-item",
      class: { "is-complete": _vm.category.completed }
    },
    [
      _c("input", {
        attrs: { type: "checkbox" },
        domProps: { checked: _vm.category.completed },
        on: { change: _vm.markComplete }
      }),
      _vm._v(" " + _vm._s(_vm.category.name) + " \n    "),
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          on: {
            click: function($event) {
              return _vm.$emit("up-category", _vm.category.id)
            }
          }
        },
        [_vm._v("UP")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          on: {
            click: function($event) {
              return _vm.$emit("down-category", _vm.category.id)
            }
          }
        },
        [_vm._v("DOWN")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "del",
          on: {
            click: function($event) {
              return _vm.$emit("del-category", _vm.category.id)
            }
          }
        },
        [_vm._v("x")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }