<template>
    <div class="form-field">
    <div v-if="formFieldWrapper.displayType==='design-edit'">
        <div v-if="formFieldWrapper.formField.template === 'short-answer'" v-bind:id="formFieldWrapper.formField.id" class="card template short-answer" data-attribute-type="single">
            <div class="card-body mb-10">
                <div class="form-group">
                    <div class="mb-10">
                        <input type="text" v-model="currentFormFieldWrapper.formField.question" class="form-control input-xlg question-name" id="" name="question" placeholder="Question" maxlength="255" minlength="1" required >
                    </div>
                    <input type="text" class="form-control" value="short answer" readonly>
                </div>
            </div>
            <div class="card-footer panel-footer-bordered">
                <div class="heading-elements">
                    <span class="heading-text text-semibold">Short Answer Question Type</span>
                    <div class="pull-right">
                        <div class="heading-form">
                            <div class="form-group">
                                <label class="checkbox-inline checkbox-right checkbox-switchery switchery-sm">
                                    <input type="checkbox" v-model="currentFormFieldWrapper.formField.required" class="switchery question-required" name="required" id="" >
                                    Required
                                </label>
                            </div>
                        </div>
                        <button type="button" v-on:click="markDelete" class="btn btn-danger btn-xs heading-btn question-delete" data-form="" data-form-field="">Delete</button>
                        <button type="button" v-on:click="markUpdate" class="btn btn-primary btn-xs" data-form="" data-form-field="">Update</button>
                        <button @click="$emit('up-field', formFieldWrapper.formField.id)" class="btn btn-light btn-xs">UP</button>
                        <button @click="$emit('down-field', formFieldWrapper.formField.id)" class="btn btn-dark btn-xs">DOWN</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="formFieldWrapper.formField.template === 'long-answer'" v-bind:id="formFieldWrapper.formField.id" class="card template long-answer" data-attribute-type="single">
            <div class="card-body mb-10">
                <div class="form-group">
                    <div class="mb-10">
                        <input type="text" v-model="currentFormFieldWrapper.formField.question" class="form-control input-xlg question-name" id="" name="question" placeholder="Question" maxlength="255" minlength="3" required>
                    </div>
                    <input type="text" class="form-control" value="long answer" readonly>
                </div>
            </div>
            <div class="card-footer panel-footer-bordered">
                <div class="heading-elements">
                    <span class="heading-text text-semibold">Long Answer Question Type</span>
                    <div class="pull-right">
                        <div class="heading-form">
                            <div class="form-group">
                                <label class="checkbox-inline checkbox-right checkbox-switchery switchery-sm">
                                    <input type="checkbox" v-model="currentFormFieldWrapper.formField.required" class="switchery question-required" name="required" id="" checked="checked">
                                    Required
                                </label>
                            </div>
                        </div>
                        <button type="button" v-on:click="markDelete" class="btn btn-danger btn-xs heading-btn question-delete" data-form="" data-form-field="">Delete</button>
                        <button type="button" v-on:click="markUpdate" class="btn btn-primary btn-xs" data-form="" data-form-field="">Update</button>
                        <button @click="$emit('up-field', formFieldWrapper.formField.id)" class="btn btn-light btn-xs">UP</button>
                        <button @click="$emit('down-field', formFieldWrapper.formField.id)" class="btn btn-dark btn-xs">DOWN</button>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="formFieldWrapper.formField.template==='multiple-choices'" v-bind:id="formFieldWrapper.formField.id" class="card template multiple-choices" data-attribute-type="multiple">
            <div class="card-body mb-10">
                <div class="form-group mb-10">
                    <input type="text" v-model="currentFormFieldWrapper.formField.question" class="form-control input-xlg question-name" id="" name="question" placeholder="Question" maxlength="255" minlength="3" required>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group mb-10">
                            <div class="input-group">
                                <span class="input-group-addon">
                                    <i class="icon-radio-checked"></i>
                                </span>
                                <ul class="list-group">
                                <li v-for="(input, index) in inputs" v-bind:key="index" class="list-group-item" >
                                    <input type="text" v-model="input.option" placeholder="option"> 
                                    <button @click="deleteRow(index)" class="btn btn-xs btn-default remove-option"><i class="dripicons-cross noti-icon"></i></button>
                                </li>
                                </ul>

                                <button @click="addRow" class="btn btn-xs btn-default add-option">Add option</button>

                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="card-footer panel-footer-bordered">
                <div class="heading-elements">
                    <div class="row border">
                    <span class="heading-text text-semibold">Multiple Choices Question Type</span>
                    <div class="pull-right">
                        <div class="heading-form">
                            <div class="form-group">
                                <label class="checkbox-inline checkbox-right checkbox-switchery switchery-sm">
                                    <input type="checkbox" v-model="currentFormFieldWrapper.formField.required" class="switchery question-required d-block" name="required" id="" checked="checked">
                                    Required
                                </label>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="row border justify-content-center">
                    <button type="button" v-on:click="markDelete" class="btn btn-danger btn-xs heading-btn question-delete" data-form="" data-form-field="">Delete</button>
                    <button type="button" v-on:click="markUpdate" class="btn btn-primary btn-xs" data-form="" data-form-field="">Update</button>
                    <button @click="$emit('up-field', formFieldWrapper.formField.id)" class="btn btn-light btn-xs">UP</button>
                    <button @click="$emit('down-field', formFieldWrapper.formField.id)" class="btn btn-dark btn-xs">DOWN</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="formFieldWrapper.formField.template==='checkboxes'" v-bind:id="formFieldWrapper.formField.id" class="card template checkboxes" data-attribute-type="multiple">
            <div class="card-body mb-10">
                <div class="form-group mb-10">
                    <input type="text" v-model="currentFormFieldWrapper.formField.question" class="form-control input-xlg question-name" id="" name="question" placeholder="Question" maxlength="255" minlength="3" required>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group mb-10">
                            <div class="input-group">
                                <span class="input-group-addon">
                                    <i class="icon-checkbox-checked"></i>
                                </span>
                                <ul class="list-group">
                                <li v-for="(input, index) in inputs" v-bind:key="index" class="list-group-item" >
                                    <input type="text" v-model="input.option" placeholder="option"> 
                                    <button @click="deleteRow(index)" class="btn btn-xs btn-default remove-option"><i class="dripicons-cross noti-icon"></i></button>
                                </li>
                                </ul>

                                <button @click="addRow" class="btn btn-xs btn-default add-option">Add option</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer panel-footer-bordered">
                <div class="heading-elements">
                    <span class="heading-text text-semibold">Chechboxes Question Type</span>
                    <div class="pull-right">
                        <div class="heading-form">
                            <div class="form-group">
                                <label class="checkbox-inline checkbox-right checkbox-switchery switchery-sm">
                                    <input type="checkbox" v-model="currentFormFieldWrapper.formField.required" class="switchery question-required" name="required" id="" checked="checked">
                                    Required
                                </label>
                            </div>
                        </div>
                        <button type="button" v-on:click="markDelete" class="btn btn-danger btn-xs heading-btn question-delete" data-form="" data-form-field="">Delete</button>
                        <button type="button" v-on:click="markUpdate" class="btn btn-primary btn-xs" data-form="" data-form-field="">Update</button>
                        <button @click="$emit('up-field', formFieldWrapper.formField.id)" class="btn btn-light btn-xs">UP</button>
                        <button @click="$emit('down-field', formFieldWrapper.formField.id)" class="btn btn-dark btn-xs">DOWN</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="formFieldWrapper.formField.template==='drop-down'" v-bind:id="formFieldWrapper.formField.id" class="card template drop-down" data-attribute-type="multiple">
            <div class="card-body mb-10">
                <div class="form-group mb-10">
                    <input type="text" v-model="currentFormFieldWrapper.formField.question" class="form-control input-xlg question-name" id="" name="question" placeholder="Question" maxlength="255" minlength="3" required>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group mb-10">
                            <div class="input-group">
                                <span class="input-group-addon sn" data-sn="1" id="">
                                    1.
                                </span>
                                <ul class="list-group">
                                <li v-for="(input, index) in inputs" v-bind:key="index" class="list-group-item" >
                                    <input type="text" v-model="input.option" placeholder="option"> 
                                    <button @click="deleteRow(index)" class="btn btn-xs btn-default remove-option"><i class="dripicons-cross noti-icon"></i></button>
                                </li>
                                </ul>

                                <button @click="addRow" class="btn btn-xs btn-default add-option">Add option</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer panel-footer-bordered">
                <div class="heading-elements">
                    <span class="heading-text text-semibold">Drop Down Question Type</span>
                    <div class="pull-right">
                        <div class="heading-form">
                            <div class="form-group">
                                <label class="checkbox-inline checkbox-right checkbox-switchery switchery-sm">
                                    <input type="checkbox" v-model="currentFormFieldWrapper.formField.required" class="switchery question-required" name="required" checked="checked">
                                    Required
                                </label>
                            </div>
                        </div>
                        <button type="button" v-on:click="markDelete" class="btn btn-danger btn-xs heading-btn question-delete">Delete</button>
                        <button type="button" v-on:click="markUpdate" class="btn btn-primary btn-xs" data-form="" data-form-field="">Update</button>
                        <button @click="$emit('up-field', formFieldWrapper.formField.id)" class="btn btn-light btn-xs">UP</button>
                        <button @click="$emit('down-field', formFieldWrapper.formField.id)" class="btn btn-dark btn-xs">DOWN</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="formFieldWrapper.formField.template==='linear-scale'" v-bind:id="formFieldWrapper.formField.id" class="card template linear-scale" data-attribute-type="multiple">
            <div class="card-body mb-10">
                <div class="form-group mb-10">
                    <input type="text" v-model="currentFormFieldWrapper.formField.question" class="form-control input-xlg question-name" id="" name="question" placeholder="Question" maxlength="255" minlength="2" required>
                </div>
                <div class="row mb-10">
                    <div class="col-md-2">
                        <div class="form-group">
                            <select v-model="linerScaleInputs.min.value" class="custom-select select min question-option key" name="min" data-width="100%" required>
                                <option value="0" id="" v-bind:selected="(this.linerScaleInputs.min.value==0)">0</option>
                                <option value="1" id="" v-bind:selected="(this.linerScaleInputs.max.value==1)">1</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="form-control-static text-center">to</div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <select v-model="linerScaleInputs.max.value" class="custom-select select max question-option key" name="max" data-width="100%" required>
                                <option value="2" id="" v-bind:selected="(this.linerScaleInputs.max.value==2)">2</option>
                                <option value="3" id="" v-bind:selected="(this.linerScaleInputs.max.value==3)">3</option>
                                <option value="4" id="" v-bind:selected="(this.linerScaleInputs.max.value==4)">4</option>
                                <option value="5" id="" v-bind:selected="(this.linerScaleInputs.max.value==5)">5</option>
                                <option value="6" id="" v-bind:selected="(this.linerScaleInputs.max.value==6)">6</option>
                                <option value="7" id="" v-bind:selected="(this.linerScaleInputs.max.value==7)">7</option>
                                <option value="8" id="" v-bind:selected="(this.linerScaleInputs.max.value==8)">8</option>
                                <option value="9" id="" v-bind:selected="(this.linerScaleInputs.max.value==9)">9</option>
                                <option value="10" id="" v-bind:selected="(this.linerScaleInputs.max.value==10)">10</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="select-labels">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="input-group">
                                    <span class="input-group-addon min-value" id="">1.</span>
                                    <input type="text" v-model="linerScaleInputs.min.label" id="" name="min-label" class="form-control question-option-label min value" placeholder="Label (min)">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <span class="input-group-addon max-value" id="">5.</span>
                                    <input type="text" v-model="linerScaleInputs.max.label" id="" name="max-label" class="form-control question-option-label max value" placeholder="Label (max)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer panel-footer-bordered">
                <div class="heading-elements">
                    <span class="heading-text text-semibold">Linear Scale Question Type</span>
                    <div class="pull-right">
                        <div class="heading-form">
                            <div class="form-group">
                                <label class="checkbox-inline checkbox-right checkbox-switchery switchery-sm">
                                    <input type="checkbox" v-model="currentFormFieldWrapper.formField.required" class="switchery question-required" name="required" id="" checked="checked">
                                    Required
                                </label>
                            </div>
                        </div>
                        <button type="button" v-on:click="markDelete" class="btn btn-danger btn-xs heading-btn question-delete" data-form="" data-form-field="">Delete</button>
                        <button type="button" v-on:click="markUpdate" class="btn btn-primary btn-xs" data-form="" data-form-field="">Update</button>
                        <button @click="$emit('up-field', formFieldWrapper.formField.id)" class="btn btn-light btn-xs">UP</button>
                        <button @click="$emit('down-field', formFieldWrapper.formField.id)" class="btn btn-dark btn-xs">DOWN</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="formFieldWrapper.formField.template==='datee'" v-bind:id="formFieldWrapper.formField.id" class="card template datee" data-attribute-type="single">
            <div class="card-body mb-10">
                <div class="form-group">
                    <div class="mb-10">
                        <input type="text" v-model="currentFormFieldWrapper.formField.question" class="form-control input-xlg question-name" id="" name="question" placeholder="Question" maxlength="255" minlength="2" required>
                    </div>
                    <div class="input-group col-md-6">
                        <span class="input-group-addon">
                            <i class="icon-calendar3"></i>
                        </span>
                        <input type="text" class="form-control" placeholder="Day, Month, Year" readonly>
                    </div>
                </div>
            </div>
            <div class="card-footer panel-footer-bordered">
                <div class="heading-elements">
                    <span class="heading-text text-semibold">Date Question Type</span>
                    <div class="pull-right">
                        <div class="heading-form">
                            <div class="form-group">
                                <label class="checkbox-inline checkbox-right ">
                                    <input type="checkbox" v-model="currentFormFieldWrapper.formField.required" class="question-required" name="required" id="" checked="checked">
                                    Required
                                </label>
                            </div>
                        </div>
                        <button type="button" v-on:click="markDelete" class="btn btn-danger btn-xs heading-btn question-delete" data-form="" data-form-field="">Delete</button>
                        <button type="button" v-on:click="markUpdate" class="btn btn-primary btn-xs" data-form="" data-form-field="">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: "FormField",
    props: ["formFieldWrapper"],
    data() {
    //console.log("inside FieldComponent...prop.formFieldWrapper:",this.formFieldWrapper);
    return {
      //currentFormField: this.formFieldWrapper.formField,
      currentFormFieldWrapper: this.formFieldWrapper,
      inputs:[],
      /*
      input = [
          {
          option: a,
          detail: b
        },
        {
          option: a,
          detail: b
        },
      ]
      */
      linerScaleInputs: {
          min:{
              value: '',
              label: ''
          },
          max:{
              value: '',
              label: ''
          }
      }
    }
  },
  watch: {
        'currentFormFieldWrapper.formField.question': function(val, oldVal){
            if (val != oldVal) {
                console.log("Question description changed to:",val);
                this.currentFormFieldWrapper.changeFlag = true;
            }
        },
        /*
        'inputs': {
                handler: function (after, before) {
                // Return the object that changed
                let changed = after.filter( function( p, idx ) {
                return Object.keys(p).some( function( prop ) {
                    return p[prop] !== before[idx][prop];
                })
                })
                // Log it
                //console.log("Input chage status:", changed)
                this.currentFormFieldWrapper.changeFlag = true;
            },
            deep: true
        },*/
        
        'linerScaleInputs.min.label': function(val, oldVal){
            if (val != this.formFieldWrapper.formField.options.min.label){
                console.log("linerScaleInputs.min.label changed to:", val);
                this.currentFormFieldWrapper.changeFlag = true;
            }
            
        },
        'linerScaleInputs.max.label': function(val, oldVal){
            if (val != this.formFieldWrapper.formField.options.max.label){
                console.log("'linerScaleInputs.max.label changed to:",val);
                this.currentFormFieldWrapper.changeFlag = true;
            }
            
        },
        'linerScaleInputs.min.value': function(val, oldVal){
            if (val != this.formFieldWrapper.formField.options.min.value){
                console.log("linerScaleInputs.min.value changed to:",val);
                this.currentFormFieldWrapper.changeFlag = true;
            }
        },
        'linerScaleInputs.max.value': function(val, oldVal){
            if (val != this.formFieldWrapper.formField.options.max.value){
                console.log("linerScaleInputs.max.value changed to:",val);
                this.currentFormFieldWrapper.changeFlag = true;
            }
        }
        
  },
  mounted(){
      this.initializeInputs();
  },
    methods: {
        initializeInputs: function(){
            let tempOptions =[];
            if (this.formFieldWrapper.formField.template != "linear-scale"){
                if(this.formFieldWrapper.formField.options!=null){
                    for(let i=0; i<this.formFieldWrapper.formField.options.length; i++){
                        tempOptions.push({
                            option: this.formFieldWrapper.formField.options[i],
                            detail: ''
                        })
                    }
                }
                this.inputs = tempOptions;
                console.log("initialized inputs:", this.inputs);
            }else{
                this.linerScaleInputs = JSON.parse(JSON.stringify(this.formFieldWrapper.formField.options));
                console.log("initialized linerScaleInputs:", this.linerScaleInputs);
            }
            
        },
        markDelete(){
           console.log("deleting field...", this.formFieldWrapper.formField.id);
           this.$emit('del-field', this.formFieldWrapper.formField.id);
        },
        markUpdate(){
            
            if (this.formFieldWrapper.formField.template != "linear-scale"){
                let options = [];
                for(let i=0; i<this.inputs.length; i++){
                    options[i] = this.inputs[i].option;
                }
                this.currentFormFieldWrapper.formField.options = options;
            }else{
                this.currentFormFieldWrapper.formField.options = this.linerScaleInputs;
            }
           console.log("updating field...", this.currentFormFieldWrapper.formField.id);
           this.$emit('update-field', this.currentFormFieldWrapper);
        },
        getUpdatedValues(){
            if (this.currentFormFieldWrapper.formField.template != "linear-scale"){
                let options = [];
                for(let i=0; i<this.inputs.length; i++){
                    options[i] = this.inputs[i].option;
                }
                this.currentFormFieldWrapper.formField.options = options;
            }else{
                this.currentFormFieldWrapper.formField.options = this.linerScaleInputs;
            }
            //currentFormFieldWithFlag.currentField = this.currentFormField;
            //return this.currentFormFieldWithFlag;
            return this.currentFormFieldWrapper;
        },
        isUpdated(){
            return this.currentFormFieldWrapper.changeFlag;
        },
        addRow() {
            this.inputs.push({
            option: '',
            detail: ''
        })
        },
        deleteRow(index) {
            this.inputs.splice(index,1)
        }
    }
}
</script>

<style scoped>
  .form-field {
    background: #f4f4f4;
    padding: 10px;
    border-bottom: 1px #ccc dotted;
  }

  .is-complete {
    text-decoration: line-through;
  }

  .del {
    background: #ff0000;
    color: #fff;
    border: none;
    padding: 5px 9px;
    border-radius: 50%;
    cursor: pointer;
    float: right;
  }

    .updown {
    background: #cccc00;
    color: #000;
    border: none;
    padding: 5px 9px;
    border-radius: 50%;
    cursor: pointer;
    float: right;
  }

</style>