var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("h5", [_vm._v("Form IDX for Design:" + _vm._s(_vm.formId))]),
      _vm._v(" "),
      _c("add-form-field-page", { on: { "add-field": _vm.addField } }),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "list-group" },
        [
          _vm._l(_vm.formFieldWrappers, function(formFieldWrapper) {
            return _c(
              "li",
              {
                key: formFieldWrapper.formField.id,
                staticClass: "list-group-item"
              },
              [
                _c("form-field-page", {
                  ref: "fieldComponents",
                  refInFor: true,
                  attrs: { formFieldWrapper: formFieldWrapper },
                  on: {
                    "del-field": _vm.deleteField,
                    "update-field": _vm.updateField,
                    "up-field": _vm.moveUp,
                    "down-field": _vm.moveDown
                  }
                })
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("hr")
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card panel panel-body submit" }, [
        _c("div", { staticClass: "card-body   " }, [
          _c("div", [
            _c(
              "button",
              {
                staticClass: "btn btn-success btn-xs formChanger",
                attrs: {
                  id: "submiter",
                  "data-loading-text": "Saving...",
                  "data-complete-text": "Save"
                },
                on: { click: _vm.submitFormDesign }
              },
              [_vm._v("Save")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: {
                  "data-toggle": "modal",
                  "data-target": "#previewModal"
                },
                on: { click: _vm.showPreview }
              },
              [_vm._v("Preview")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.readyForPreview
        ? _c(
            "div",
            {
              staticClass: "modal",
              attrs: {
                id: "previewModal",
                "data-keyboard": "false",
                "data-backdrop": "static"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-dialog",
                  staticStyle: { "max-width": "100%", "max-height": "100%" }
                },
                [
                  _c("div", { staticClass: "modal-content" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-body" },
                      [
                        _c("form-exec-page", {
                          key: _vm.formExecComponentKey,
                          attrs: {
                            previewFormFieldWrappers:
                              _vm.previwFormFieldWrappers,
                            "form-id": _vm.formId,
                            "exec-type": "preview"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._m(1)
                  ])
                ]
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Form Design Preview")]),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "close", attrs: { "data-dismiss": "modal" } },
        [_vm._v("×")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { "data-dismiss": "modal" } },
        [_vm._v("Close")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }