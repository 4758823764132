var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("add-category-page", { on: { "add-category": _vm.addCategory } }),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "list-group" },
        [
          _vm._l(_vm.categories, function(category) {
            return _c(
              "li",
              { key: category.id, staticClass: "list-group-item" },
              [
                _c("category-item-page", {
                  attrs: { category: category },
                  on: {
                    "del-category": _vm.deleteCategory,
                    "up-category": function($event) {
                      return _vm.moveUp(category.id)
                    },
                    "down-category": function($event) {
                      return _vm.moveDown(category.id)
                    }
                  }
                })
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("hr")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }