<template>
<div id="app">
    <h5>Form ID for {{execType}}:{{formId}}</h5>
    <form action="/api/forms/submitform" method="POST" >
    <input type="hidden" name="formId" v-bind:value="formId">
    <ul class="list-group">

        <li class="list-group-item" v-for="formFieldWrapper in formFieldWrappers" :key="formFieldWrapper.formField.id">
            <form-field-exec-page v-on:update-field="updateField" v-bind:formFieldWrapper="formFieldWrapper" ref = "fieldComponents"></form-field-exec-page>
        </li> 
        <hr/>

    </ul>

    <div v-if="execType==='live'" class="card panel panel-body submit">
        <div class="card-body   ">
            <div>
                <button type="button" v-on:click="submitFormExec" class="btn btn-success btn-xs formChanger" id="save" data-loading-text="Saving..." data-complete-text="Save">Submit</button>
                <input type="submit" value="Submit2" name="Submit2" class="btn btn-warning">
            </div>
        </div>
    </div>
    <div v-if="execType==='live-update'" class="card panel panel-body submit">
        <div class="card-body   ">
            <div>
                <button type="button" v-on:click="updateSubmittedForm" class="btn btn-success btn-xs formChanger" id="save" data-loading-text="Saving..." data-complete-text="Save">Update</button>
                <input type="submit" value="Submit2" name="Submit2" class="btn btn-warning">
            </div>
        </div>
    </div>
    </form>
</div>
</template>

<script>

export default {
    props: ["previewFormFieldWrappers", "formId", "submissionId", "execType"],
    data: function(){
        return{
            formFields: [],
            formFieldWrappers: []
            /*
                {
                    formField: {
                        id:1
                    },
                    changeFlag: false,
                    displayType: 'design-edit'
                },
                 {
                    formField: {
                        id:2
                    },
                    changeFlag: false,
                    displayType: 'design-edit'
                }
            ]*/
        }
    },
    mounted(){
        this.loadFields();
    },
    methods: {
        loadFields: function(){
            console.log("inside formExec component:",this.formId, this.execType, this.previewFormFieldWrappers);
            /*this.formField.smaple = [
              {
                  attribute: "short_answer.1f5e76b8",
                  created_at: "2020-12-17T14:51:33.000000Z",
                  filled: false,
                  form_definition_id: 2,
                  id: 17,
                  name: null,
                  options: null,
                  question: null,
                  required: null,
                  status: 1,
                  template: "short-answer",
                  updated_at: "2020-12-17T14:51:33.000000Z"
              }
              this.formFieldWrappers.sample =[
                  {
                    formField: this.formField,
                    displayType: design-edit/execute
                    changeFlag: false/true
                  }
              ]
            ]*/
            if (this.execType === 'preview'){
                this.formFieldWrappers = [...this.previewFormFieldWrappers];
            }else if (this.execType === 'live-update'){
                let url = '/api/forms/submissiondetail/'+this.submissionId;
                axios.get(url)
                .then( (response) => {
                    console.log("Submission detail retrieved:", response.data);
                    //this.formFields = response.data.form_definition_fields;
                    
                    for (let i = 0; i < response.data.submittedFields.length; i++) {
                        let tempFieldWrapper = {
                            submissionId: this.submissionId,
                            formField: response.data.submittedFields[i],
                            changeFlag: false,
                            displayType: 'execute',
                            executionType: 'update'
                        }
                        this.formFieldWrappers.push(tempFieldWrapper);
                    }
                    console.log("Retrieved fields:",this.formFieldWrappers);
                })
                .catch(function(error){
                    console.log(error);
                });
            }else{
                let url = '/api/forms/'+this.formId;
                axios.get(url)
                .then( (response) => {
                    console.log(response.data.form_definition_fields);
                    //this.formFields = response.data.form_definition_fields;
                    for (let i = 0; i < response.data.form_definition_fields.length; i++) {
                        let tempFieldWrapper = {
                            formField: response.data.form_definition_fields[i],
                            changeFlag: false,
                            displayType: 'execute',
                            executionType: 'empty'
                        }
                        this.formFieldWrappers.push(tempFieldWrapper);
                    }
                    console.log("Retrieved fields:",this.formFieldWrappers);
                })
                .catch(function(error){
                    console.log(error);
                });
            }
        },
        addField: function(newField){
            //const { fieldType, fieldId } = newField;
            console.log("Calling add field for form and fieldType:", this.formId, newField.template);
            
            axios.post('/api/forms/addfield', {
                formId: this.formId,
                template: newField.template
            })
            .then((response) => {
                console.log("Successfully added:");
                const addedFieldWrapper = {
                    formField: response.data,
                    diplayType: 'design-edit',
                    changeFlag: false
                };
                addedFieldWrapper.formField.options = [];
                this.formFieldWrappers = [...this.formFieldWrappers, addedFieldWrapper];
                console.log(this.formFieldWrappers);
            })
            .catch(err => console.log(err));
        
        },
        updateField: function(updatedFieldWrapper){
            console.log("Calling update for fieldObject:", updatedFieldWrapper);
            
            axios.post('/api/forms/updatefield', {
                id: this.formId,
                name: updatedFieldWrapper.formField.name,
                options: updatedFieldWrapper.formField.options,
                question: updatedFieldWrapper.formField.question,
                required: updatedFieldWrapper.formField.required,
                status: updatedFieldWrapper.formField.status,
                template: updatedFieldWrapper.formField.template
            })
            .then((response) => {
                console.log("Successfully updated:");
                console.log(response.data);
            })
            .catch(err => console.log(err));
        
        },
         deleteField(selectedFieldId) {
             console.log("calling delete for formID and fieldID:", selectedFieldId);
             for (let i = 0; i < this.formFieldWrappers.length; i++) {
                if (this.formFieldWrappers[i].formField.id === selectedFieldId){
                    let url = '/api/forms/deletefield/'+selectedFieldId;
                    console.log('URL:',url);
                    axios.delete(url)
                    .then((response) => {
                        console.log("Successfully deleted:",response);
                        this.formFieldWrappers.splice(i, 1);
                    })
                    .catch(err => console.log(err));
                    }
                    //console.log(this.categories[i]);
                }     
        },
        submitFormExec() {
            console.log("Submitting the form...");
            const bodyFormData = new FormData();
            bodyFormData.append('formId',this.formId);
            bodyFormData.append('submissionType','new');
            /*
            product_id_list.forEach((item) => {
                bodyFormData.append('product_id_list[]', item);
            });
            */
            for (let i=0; i<this.formFieldWrappers.length; i++){
                let filledFormField = this.$refs.fieldComponents[i].getFilledValues();
                
                console.log("making form data for field:",filledFormField);
                if (filledFormField.template == 'checkboxes'){
                    for (let x=0; x<filledFormField.attributeValueArray.length; x++){
                        console.log("appending form data:",filledFormField.attributeValueArray[x])
                        bodyFormData.append(filledFormField.attribute+'[]', filledFormField.attributeValueArray[x]);
                    }
                console.log("bodyFormData:",bodyFormData.getAll(filledFormField.attribute+'[]'));
                }else{
                    bodyFormData.append(filledFormField.attribute, filledFormField.attributeValue);
                }
                  
            }
            
           axios.post('/api/forms/submitform', bodyFormData)
            .then((response) => {
                console.log("Filled form successfully submitted:");
                console.log(response.data);
            })
            .catch(err => console.log(err));
           
        },
        
        updateSubmittedForm() {
            console.log("Submitting the form for update...");
            const bodyFormData = new FormData();
            bodyFormData.append('submissionId',this.submissionId);
            bodyFormData.append('submissionType','update');
            for (let i=0; i<this.formFieldWrappers.length; i++){
                let filledFormField = this.$refs.fieldComponents[i].getFilledValues();
                console.log("making form data for field:",filledFormField);
                if (filledFormField.template == 'checkboxes'){
                    for (let x=0; x<filledFormField.attributeValueArray.length; x++){
                        console.log("appending form data:",filledFormField.attributeValueArray[x])
                        bodyFormData.append(filledFormField.attribute+'[]', filledFormField.attributeValueArray[x]);
                    }
                console.log("bodyFormData:",bodyFormData.getAll(filledFormField.attribute+'[]'));
                }else{
                    bodyFormData.append(filledFormField.attribute, filledFormField.attributeValue);
                }
                  
            }
            axios.post('/api/forms/updatesubmission', bodyFormData)
            .then((response) => {
                console.log("Filled form successfully submitted for update:");
                console.log(response.data);
            })
            .catch(err => console.log(err));
        }
    }
}
</script>


